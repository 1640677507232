/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Program from '../../interfaces/program.interface';
import { userBenefitSelector } from '../../redux/selectors/benefits.selector';
import { userOrgSelector } from '../../redux/selectors/person.selector';
import { SubtitleDisplay } from './Subtitle.styled';

type PropTypes = {
  programs: Program[];
  connected?: boolean;
  big?: boolean;
  mainTitle?: string;
};

const Subtitle: FC<PropTypes> = ({ programs = [], connected, big = false, mainTitle }) => {
  const [programPackage, setProgramPackage] = useState<string>('');
  const userOrg = useSelector(userOrgSelector);
  const userBenefits = useSelector(userBenefitSelector);
  useEffect(() => {
    if (!connected) {
      return setProgramPackage('');
    }
    const programsIds = programs.map((program) => program._id);
    const userBenefit = userBenefits.find((uBenefit) => programsIds.includes(uBenefit.programId));
    const targetProgramPackage = userBenefit && programs.find((program) => program._id === userBenefit.programId);
    if (userBenefit) {
      return setProgramPackage(targetProgramPackage.title);
    }
  }, [userOrg, programs, userBenefits]);
  const isHeadersMatch = programPackage === mainTitle;
  return (
    <SubtitleDisplay big>
      &nbsp;
      {!isHeadersMatch && programPackage}
    </SubtitleDisplay>
  );
};

export default Subtitle;
