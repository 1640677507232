import { differenceInMonths } from 'date-fns';
import Person from '../interfaces/person.interface';
import { findUserJob } from '../redux/selectors/person.selector';

const calculatePersonProbation = (person: Person, date: Date) => {
  if (!person.tabNumbers.length) {
    return 0;
  }
  if (person.seniority) {
    return differenceInMonths(date, new Date(person.seniority));
  }
  let { currentJob, currentTabNumber } = findUserJob(person);
  if (currentTabNumber?.seniorityStart) {
    return differenceInMonths(date, new Date(currentTabNumber.seniorityStart));
  }
  if (currentJob?.bDate) {

    let difMonth = differenceInMonths(date, new Date(currentJob.bDate));
    return difMonth;
  }
  return 0;
}

export default calculatePersonProbation;