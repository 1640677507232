// Core
import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'oidc-react';
import { Loader } from '@skbkontur/react-ui';
import { Outlet, useNavigate } from 'react-router';

// Components
import RenderCityModal from '../../components/CityModal/indext';
import Header from '../../components/Header';
import SidePageProgram from '../../components/SidePageProgram';

// Store
import { cityModalSelector, isCityLoadingSelector } from '../../redux/selectors/global.selector';
import { personSelector, appInitialized } from '../../redux/selectors/person.selector';
import { handleCityModal } from '../../redux/slices/globalSlice';
import { fetchCities } from '../../redux/thunks/citiesThunk';

// Styles
import { LayoutWrapper } from './MainLayout.styled';
import { useAppDispatch } from '../../redux/hooks';

type Props = {
  children?: ReactNode;
};

const UserPageLayout: FC<Props> = ({ children }) => {
  const person = useSelector(personSelector);
  const cityLoaded = useSelector(isCityLoadingSelector);
  const cityModal = useSelector(cityModalSelector);
  const isAppInit = useSelector(appInitialized);
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (!isAppInit) {
        auth.signOut();
        auth.userManager.clearStaleState();
        navigate('/');
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (person && person.name && !person.personSetCity) {
      dispatch(fetchCities());
      dispatch(handleCityModal());
    }
  }, [person]);
  return (
    <LayoutWrapper>
      <Loader active={!cityLoaded} type="normal" delayBeforeSpinnerShow={0} minimalDelayBeforeSpinnerHide={0}>
        {cityModal && person.name && cityLoaded && (
          <RenderCityModal />)}
        <Header />
        {children}
        <Outlet />
        <SidePageProgram />
      </Loader>
    </LayoutWrapper>
  );
};

export default UserPageLayout;
