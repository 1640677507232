/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import { Button } from '@skbkontur/react-ui';
import { isAfter, isBefore } from 'date-fns';
import { format } from 'date-fns/esm';
import { ru } from 'date-fns/locale';
import React, { FC } from 'react';
import { arraysIntersect } from '../../functions/arrayIntersect';
import calculatePersonProbation from '../../functions/calculateUserExperience';
import checkPersonTrial from '../../functions/checkPersonTrial';
import Benefit from '../../interfaces/benefit.interface';
import OrderDTO from '../../interfaces/orderDTO.interface';
import { connectedBenefits, isInUserBenefits, notConnectedBenefits, parentInUserBenefits, selectBenefitById, userBenefitSelector } from '../../redux/selectors/benefits.selector';
import { citySelector, personSelector } from '../../redux/selectors/person.selector';
import { addOrder, editOrder, setCurrentBenefit } from '../../redux/slices/benefitsSlice';
import { ProgramPageCanEdit, ProgramPageHeadingP, ProgramPageHeadingPWithColor, ProgramPageHeadingStatus } from './HeadingStatus.styled';
import { mainProgramCitySelector } from '../../redux/selectors/global.selector';
import { registerDate } from '../../utils/currentRegisterData';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

type PropTypes = {
  benefit: Benefit,
  isAdditionalPackage?: boolean,
};

export const mapChildren = (child: Benefit): OrderDTO => {
  return {
    benefit: child._id,
    creditPlan: null,
    program: null,
    offset: null,
    signatureUrl: null,
  };
};

const HeadingStatus: FC<PropTypes> = ({ benefit, isAdditionalPackage }) => {
  const dispatch = useAppDispatch();

  const person = useAppSelector(personSelector);
  const userBenefits = useAppSelector(userBenefitSelector);
  const probation = calculatePersonProbation(person, benefit.connected ? new Date() : new Date(benefit.bDate));
  const isConnected = !!useAppSelector(isInUserBenefits(benefit._id));
  const isTrial = checkPersonTrial(person, benefit.bDate);
  const notConnectedPrograms = useAppSelector(notConnectedBenefits);
  const hasParent = benefit.parent || benefit.parents?.length;
  const parentBenefit = useAppSelector(selectBenefitById(benefit.parent));
  const hasConnectedParent = useAppSelector(parentInUserBenefits(benefit.parents ?? []));
  const hasConnectedVZR = useAppSelector(connectedBenefits).find((item) => item.type === 'vzr');
  const isRegistrationStarted = isAfter(new Date(), new Date(benefit.activationCondition.registrationStart));
  const isRegistrationEnded = isAfter(new Date(), new Date(benefit.activationCondition.registrationUntil));
  const isBenefitEnd = isAfter(new Date(), new Date(benefit.eDate));
  const isStarted = isAfter(new Date(), new Date(benefit.bDate)) && !isBenefitEnd;
  const canEdit = probation && isConnected && !isStarted && isRegistrationStarted && !isRegistrationEnded;
  const isFullPriceVzr = benefit.type === 'vzr' && benefit.programs.some((program) => {
    const programsWithOffset = program.offsetCondition.filter((offset) => offset.offset === 100 && probation >= offset.condition);
    return programsWithOffset.length;
  });

  const connectedProgram = userBenefits.find((item) => item.benefitId === benefit._id);

  const { city } = useAppSelector(personSelector);

  const mainProgramCity = useAppSelector(mainProgramCitySelector);

  const isItDant = benefit.meta?.isForDevelopers;

  const benefitYear = new Date(benefit.bDate).getFullYear();

  const handleEditOrder = () => {
    dispatch(editOrder({ id: benefit._id }));
  };

  const connectBenefit = () => {
    dispatch(setCurrentBenefit({ id: benefit._id }));
    const blankOrder: OrderDTO = {
      benefit: benefit._id,
      creditPlan: null,
      program: null,
      offset: null,
      signatureUrl: person.signatures && person.signatures[benefitYear] ? person.signatures[benefitYear] : null,
      children: benefit.children?.length ? benefit.children.map((child) => mapChildren(child)) : undefined,
    };
    if (benefit.type === 'DMS') {
      if (person.programsScheme?.['2025']?.connectedItProgram !== null && person.programsScheme?.['2025']?.connectedItProgram === false) {
        const developerAdditionalProgramme = notConnectedPrograms.find((program) => program.parents?.includes(benefit._id));
        if (developerAdditionalProgramme && developerAdditionalProgramme?.meta?.isForDevelopers) {
          blankOrder.children = [{
            benefit: developerAdditionalProgramme._id,
            creditPlan: 0,
            program: developerAdditionalProgramme.programs[0]._id,
            offset: 100,
            prefix: developerAdditionalProgramme.programs[0].prefix,
            signatureUrl: person.signatures && person.signatures[benefitYear] ? person.signatures[benefitYear] : null,
          }];
        }
      }
      if (person.programsScheme?.['2025']?.connectedAdditionalProgramme != null && !person.programsScheme?.['2025']?.connectedAdditionalProgramme) {
        const additionalProgrammes = notConnectedPrograms
          .filter((program) => program?.meta?.canBeGift)
          .filter((programme) => {
            if (programme.parents?.length) {
              if (programme.parents.includes(benefit._id)) {
                return true;
              }
              return false;
            }
            return true;
          });
        if (additionalProgrammes) {
          blankOrder.additionalPrograms = [];
          additionalProgrammes.forEach((programme) => {
            blankOrder.additionalPrograms.push({
              benefit: programme._id,
              creditPlan: 0,
              program: null,
              offset: 0,
              signatureUrl: person.signatures && person.signatures[benefitYear] ? person.signatures[benefitYear] : null,
            });
          });
        }
      }
    }
    dispatch(addOrder(blankOrder));
  };

  const userProgramsIds = userBenefits.map((item) => item.benefitId);

  if (!isConnected && benefit.showUntil && isBefore(new Date(), new Date(registerDate().startDate)) && isAfter(new Date(), new Date(registerDate(-1).endDAte))) {
    return (
      <ProgramPageHeadingP>
        Подать заявку на подключение возможно после
        {' '}
        {format(new Date(registerDate().startDate), 'dd MMMM', { locale: ru })}
      </ProgramPageHeadingP>
    );
  }
  if (isTrial) {
    return (
      <ProgramPageHeadingStatus>
        <ProgramPageHeadingP>Подключить можно после испытательного срока</ProgramPageHeadingP>
      </ProgramPageHeadingStatus>
    );
  }

  if (benefit.meta?.exclude?.length && arraysIntersect(benefit.meta?.exclude, userProgramsIds) && !isConnected) {
    return (
      <ProgramPageHeadingStatus>
        <ProgramPageHeadingP>Телемедицина входит в программу ДМС автоматически</ProgramPageHeadingP>
      </ProgramPageHeadingStatus>
    );
  }

  if (new Date(benefit.eDate).getFullYear() === 2024 && userBenefits.length && benefit.title.toLowerCase().includes('стомат') && mainProgramCity && mainProgramCity?.toLowerCase() !== city.name.toLowerCase() && !isConnected) {
    return (
      <ProgramPageHeadingStatus>
        <ProgramPageHeadingPWithColor>
          Нельзя подключить в г.
          {' '}
          {city.name}
          , потому что основная ДМС подключена в г.
          {' '}
          {mainProgramCity}
          .
        </ProgramPageHeadingPWithColor>
      </ProgramPageHeadingStatus>
    );
  }

  if (isItDant && hasConnectedParent && !isConnected) {
    return (
      <Button disabled={!!hasConnectedVZR && benefit.title === 'ДМС*'} onClick={() => connectBenefit()} use="primary" size="medium">
        Подключить
      </Button>
    );
  }

  if (isItDant && isConnected) {
    return (
      <ProgramPageHeadingStatus>
        <ProgramPageHeadingP>Отключается вместе с основной программой ДМС</ProgramPageHeadingP>
      </ProgramPageHeadingStatus>
    );
  }
  if (isItDant) {
    return (
      <ProgramPageHeadingStatus>
        <ProgramPageHeadingP>Подключится бесплатно вместе с основной программой ДМС</ProgramPageHeadingP>
      </ProgramPageHeadingStatus>
    );
  }

  if (benefit.type === 'vzr' && !isFullPriceVzr) {
    return (
      <ProgramPageHeadingStatus>
        <ProgramPageHeadingP>Подключить можно после испытательного срока</ProgramPageHeadingP>
      </ProgramPageHeadingStatus>
    );
  }

  return (
    <ProgramPageHeadingStatus>
      {(!isTrial)
        && (
          (!isRegistrationStarted && (
            <ProgramPageHeadingP>
              Подключить можно
              {' '}
              {format(new Date(benefit.activationCondition.registrationStart), 'dd MMMM', { locale: ru })}
            </ProgramPageHeadingP>
          ))
          || (!!hasParent && !hasConnectedParent && isRegistrationStarted && !isRegistrationEnded && (
            <ProgramPageHeadingP>
              Сначала подключите ДМС
            </ProgramPageHeadingP>
          ))
          || (
            (isRegistrationStarted && !isRegistrationEnded && !isConnected && (
              <Button disabled={!!hasConnectedVZR && benefit.title === 'ДМС*'} onClick={() => connectBenefit()} use="primary" size="medium">
                Подключить
              </Button>
            )))
          || (
            canEdit && (
              <>
                <ProgramPageCanEdit isAdditionalPackage={isAdditionalPackage}>
                  <ProgramPageHeadingP>
                    Начнёт действовать
                    {' '}
                    {format(new Date(benefit.bDate), 'dd MMMM', { locale: ru })}
                  </ProgramPageHeadingP>
                  <div>
                    <Button onClick={() => handleEditOrder()} use="default" size="medium">
                      Изменить программу
                    </Button>
                    <ProgramPageHeadingP>
                      Можно изменить до
                      {' '}
                      {format(new Date(benefit.activationCondition.registrationUntil), 'dd MMMM', { locale: ru })}
                      {'. '}
                      &emsp;
                      {mainProgramCity && benefit.type === 'DMS' && ` Город страхования: ${connectedProgram ? connectedProgram.city : mainProgramCity}`}
                    </ProgramPageHeadingP>
                  </div>
                </ProgramPageCanEdit>
              </>
            ))
          || (
            isConnected && !isStarted && !canEdit && (
              <ProgramPageHeadingP>
                Начнёт действовать
                {' '}
                {format(new Date(benefit.bDate), 'dd MMMM', { locale: ru })}
                {'. '}
                &emsp;
                {mainProgramCity && benefit.type === 'DMS' && ` Город страхования: ${connectedProgram ? connectedProgram.city : mainProgramCity}`}
              </ProgramPageHeadingP>
            ))
          || (
            isConnected && isStarted && (
              <ProgramPageHeadingP>
                Действует до
                {' '}
                {format(new Date(benefit.eDate), 'dd MMMM', { locale: ru })}
                {'. '}
                &emsp;
                {mainProgramCity && benefit.type === 'DMS' && ` Город страхования: ${connectedProgram ? connectedProgram.city : mainProgramCity}`}
              </ProgramPageHeadingP>
            ))
          || (
            isBenefitEnd && isConnected && (
              <ProgramPageHeadingP disabled={isBenefitEnd && isConnected}>
                Срок действия закончился
                {' '}
                {format(new Date(benefit.eDate), 'dd MMMM', { locale: ru })}
              </ProgramPageHeadingP>
            )
          )
        )}

    </ProgramPageHeadingStatus>
  );
};

export default HeadingStatus;
