/* eslint-disable no-underscore-dangle */
import { isAfter, isSameYear, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { FC, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import sameYearDetect from '../../functions/sameYearDetect';
import Benefit from '../../interfaces/benefit.interface';
import UserBenefit from '../../interfaces/user-benefit.interface';
import { userBenefitSelector } from '../../redux/selectors/benefits.selector';
import { setCurrentBenefit } from '../../redux/slices/benefitsSlice';
import InfoTag from '../InfoTag';
import Title from '../Title';
import { ProgramCardSub, ProgramCardWrapper, ProgramCardWrapperWide, SubAdds } from './Program.styled';
import Subtitle from '../Subtitle';

type PropTypes = {
  benefit: Benefit
  connected?: boolean;
};

const Program: FC<PropTypes> = ({ benefit, connected = false }) => {
  const userBenefits = useSelector(userBenefitSelector);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRegistrationStarted = isAfter(new Date(), new Date(benefit.activationCondition.registrationStart));
  const isRegistrationEnded = isAfter(new Date(), new Date(benefit.activationCondition.registrationUntil));

  const [programPackage, setProgramPackage] = useState<string>('');
  const [isMain] = useState(benefit.type === 'DMS' || benefit.type === 'vzr' || null);
  const [lines, setLines] = useState(1);

  useEffect(() => {
    const programsIds = benefit.programs.map((program) => program._id);
    const userBenefit = userBenefits.find((uBenefit) => programsIds.includes(uBenefit.programId));
    const targetProgramPackage = userBenefit && benefit.programs.find((program) => program._id === userBenefit.programId);
    if (userBenefit) {
      if (benefit.title.includes(targetProgramPackage.title)) {
        const titleWords = benefit.title.split(' ');
        let newTitle = targetProgramPackage.title;
        titleWords.forEach((word) => {
          newTitle = newTitle.replace(word, '');
        });
        return setProgramPackage(newTitle);
      }
      return setProgramPackage(targetProgramPackage.title);
    }
  }, [benefit.programs, userBenefits]);

  const beginDate = new Date(benefit.bDate);
  const endDate = new Date(benefit.eDate);

  const hasParent = (parent: string | undefined) => {
    const matchParent = userBenefits.find((bnf) => bnf.benefitId === parent);
    return !!matchParent;
  };

  const programDetail = () => {
    dispatch(setCurrentBenefit({ id: benefit._id }));
    navigate(`/program/${benefit._id}`);
  };
  useEffect(() => {
    if (titleRef.current) {
      const elHeight = titleRef.current.offsetHeight;
      if (elHeight > 25) {
        setLines(2);
      }
    }
  }, [titleRef]);

  if (!isMain) {
    return (
      <ProgramCardWrapper onClick={() => programDetail()}>
        <h4 ref={titleRef}>
          {benefit.title}
          {' '}
          {programPackage}
          {/* <Subtitle connected={benefit.connected} programs={benefit.programs} big /> */}
        </h4>
        {benefit.connected && (
          <SubAdds lines={lines}>
            {format(beginDate, sameYearDetect(beginDate), { locale: ru })}
            {` ${!(isSameYear(beginDate, endDate)) ? '' : beginDate.getFullYear()} – `}
            {format(endDate, sameYearDetect(endDate, beginDate), { locale: ru })}
          </SubAdds>
        )}
        {benefit.type !== 'relative' && (<InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} />)}
        <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} simple />
      </ProgramCardWrapper>
    );
  }

  if (isMain) {
    return (
      <ProgramCardWrapperWide className="wideCard" onClick={() => programDetail()}>
        <Title type="h3">
          {benefit.title}
          {' '}
          {programPackage}
        </Title>
        <ProgramCardSub>
          {benefit.connected && (
            <SubAdds>
              {format(beginDate, sameYearDetect(beginDate), { locale: ru })}
              {` ${!(isSameYear(beginDate, endDate)) ? '' : beginDate.getFullYear()} – `}
              {format(endDate, sameYearDetect(endDate, beginDate), { locale: ru })}
            </SubAdds>
          )}
          <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} />
          <InfoTag
            connected={benefit.connected}
            benefitId={benefit._id}
            child={benefit.parent}
            programs={benefit.programs}
            simple
          />
        </ProgramCardSub>
        {(benefit.children && benefit.children.length) && benefit.children.map((child) => (
          <ProgramCardSub key={child._id}>
            {child.title}
            <Subtitle connected={benefit.connected} programs={child.programs} mainTitle={child.title} />
            {child.connected && (
              <SubAdds>
                {format(new Date(child.bDate), sameYearDetect(child.bDate), { locale: ru })}
                {` ${!(isSameYear(beginDate, endDate)) ? '' : beginDate.getFullYear()} – `}
                {format(((new Date(child.eDate) > endDate) ? endDate : new Date(child.eDate)), sameYearDetect(child.eDate, child.bDate), { locale: ru })}
              </SubAdds>
            )}
            {!hasParent(child.parent) && (
              <SubAdds>
                Сначала подключите
                {' '}
                {benefit.title}
              </SubAdds>
            )}
            {(hasParent(child.parent) && !child.connected) && (
              <SubAdds>Не подключено</SubAdds>
            )}
            <InfoTag connected={child.connected} benefitId={child._id} child={child.parent} programs={child.programs} />
            <InfoTag connected={child.connected} benefitId={child._id} child={child.parent} programs={child.programs} simple />
          </ProgramCardSub>
        ))}
      </ProgramCardWrapperWide>
    );
  }

  return (
    <>
    </>
  );
};

export default Program;
