import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Person from '../../../interfaces/person.interface';
import { useAppSelector } from '../../../redux/hooks';
import { findUserJob } from '../../../redux/selectors/person.selector';
import useLocalStorage from '../../../hooks/useLocalStorage';
import AvatarPlaceHolder from '../../../components/ AvatarPlaceholder.tsx/AvatarPlaceholder';
import { getJobName } from './utils/getJobName';

const StyledLi = styled.li`
    width: fit-content;
`;
const UserCardWrapper = styled(Link)`
    display:flex;
    gap: 15px;
    align-items: center;
    text-decoration:none;
    color:#222222;

    * {
        margin: 0;
        padding: 0;
    }
`;

const UserAvatar = styled.img`
    border-radius: 100%;
    width: 45px;
    aspect-ratio: 1/1;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2;
    font-size: 16px;

    .name {
        font-weight:700;
    };

    .work {
        color:#757575;
    };
`;

type Props = {
  user: Person;
};

const UserHistoryItem:FC<Props> = ({ user }) => {
  const { firstName, lastName, middleName } = user;
  const [historyUsers, setHistoryUsers] = useLocalStorage('historyUsers', []);
  const { currentJob } = findUserJob(user);

  const handleClick = (addUser:Person) => {
    const newUsers = (historyUsers as Person[]).filter((historUser) => historUser._id !== addUser._id);
    newUsers.unshift(addUser);
    setHistoryUsers(newUsers);
  };

  return (
    <StyledLi>
      <UserCardWrapper onClick={() => handleClick(user)} to={`/admin/employee/${user._id}`}>
        <AvatarPlaceHolder firstWord={user.lastName} secondWord={user.firstName} />
        <UserInfo>
          <p className="name">

            {lastName || ''}
            {' '}
            {firstName || ''}
            {' '}
            {middleName || ''}
          </p>
          <p className="work">{currentJob ? getJobName(currentJob.name) : 'Должность не указана'}</p>
        </UserInfo>
      </UserCardWrapper>
    </StyledLi>
  );
};

export default UserHistoryItem;
