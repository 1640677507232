import React, { FC, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isAfter } from 'date-fns';
import Benefit from '../../interfaces/benefit.interface';
import { isInUserBenefits, userBenefitSelector } from '../../redux/selectors/benefits.selector';
import HeadingStatus from '../HeadingStatus';
import InfoTag from '../InfoTag';
import NavTitle from '../NavTitle';
import { ProgramPageHeading, ProgramPageRow, ProgramPageTitle } from './ProgramPageHeading.styled';
import { mainProgramCitySelector } from '../../redux/selectors/global.selector';
import InfoTagSimple from '../InfoTagSimple';
import { personSelector } from '../../redux/selectors/person.selector';

type PropTypes = {
  benefit: Benefit
};

const ProgramHeading: FC<PropTypes> = ({ benefit }) => {
  const [benefitType] = useState(benefit.type === 'DMS' ? 'main' : 'optional');
  const isBonuses = benefit.type === 'base';
  const titleString = useRef(null);
  const person = useSelector(personSelector);
  const [isHeadingHasMoved, setIsHeadingHasMoved] = useState(true);
  const userBenefits = useSelector(userBenefitSelector);
  const [programPackage, setProgramPackage] = useState<string>('');
  const isConnected = !!useSelector(isInUserBenefits(benefit._id));
  const isRegistrationStarted = isAfter(new Date(), new Date(benefit.activationCondition.registrationStart));
  const isRegistrationEnded = isAfter(new Date(), new Date(benefit.activationCondition.registrationUntil));
  const isBenefitEnd = isAfter(new Date(), new Date(benefit.eDate));
  const isStarted = isAfter(new Date(), new Date(benefit.bDate)) && !isBenefitEnd;

  const canEdit = isConnected && !isStarted && isRegistrationStarted && !isRegistrationEnded;

  const { city } = useSelector(personSelector);

  const mainProgramCity = useSelector(mainProgramCitySelector);

  useEffect(() => {
    setIsHeadingHasMoved(titleString.current?.offsetHeight > 115);
  }, [titleString, isHeadingHasMoved]);

  useEffect(() => {
    const programsIds = benefit.programs.map((program) => program._id);
    const userBenefit = userBenefits.find((uBenefit) => programsIds.includes(uBenefit.programId));
    const targetProgramPackage = userBenefit && benefit.programs.find((program) => program._id === userBenefit.programId);
    if (userBenefit) {
      if (benefit.title.includes(targetProgramPackage.title)) {
        const titleWords = benefit.title.split(' ');
        let newTitle = targetProgramPackage.title;
        titleWords.forEach((word) => {
          newTitle = newTitle.replace(word, '');
        });
        return setProgramPackage(newTitle);
      }
      return setProgramPackage(targetProgramPackage.title);
    }
  }, [benefit.programs, userBenefits]);

  return (
    <ProgramPageHeading type={benefitType} isBonuses={isBonuses}>
      <ProgramPageTitle>
        <NavTitle path="/" isBonuses={isBonuses}>
          {benefit.title}
          {' '}
          {programPackage}
          {/* <Subtitle connected={benefit.connected} programs={benefit.programs} /> */}
        </NavTitle>

      </ProgramPageTitle>

      {benefit.isFeed && person.compensation && (
        <ProgramPageRow>
          <InfoTagSimple text={`${person.compensation}`} />
        </ProgramPageRow>
      )}
      {benefit.type !== 'base' && benefit.type !== 'relative' && isConnected && canEdit && (
        <ProgramPageRow>
          <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} />
          <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} simple />

        </ProgramPageRow>
      )}
      {benefit.title.toLowerCase().includes('стомат') && mainProgramCity && mainProgramCity?.toLowerCase() !== city.name.toLowerCase() && !isConnected && benefit.type !== 'base' && benefit.type !== 'relative'
        ? (
          <>
            <ProgramPageRow>
              <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} />
              <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} simple />
              <HeadingStatus benefit={benefit} />
            </ProgramPageRow>
          </>
        ) : (
          <>
            {benefit.type !== 'base' && benefit.type !== 'relative' && (
              <ProgramPageRow>
                {(!isConnected || !canEdit) && (
                  <>
                    <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} />
                    <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} simple />

                  </>
                )}
                <HeadingStatus benefit={benefit} />
              </ProgramPageRow>
            )}
          </>
        )}
    </ProgramPageHeading>
  );
};

export default ProgramHeading;
