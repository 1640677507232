import React, { FC } from 'react';

type Props = {
  color?:string;
};

const EduIcon:FC<Props> = ({ color = '#F15C57' }) => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.8" y="1.8" width="34.4" height="34.4" rx="17.2" fill={color} />
      <rect x="1.8" y="1.8" width="34.4" height="34.4" rx="17.2" stroke="white" strokeWidth="2.4" />
      <g clipPath="url(#clip0_6467_27859)">
        <g clipPath="url(#clip1_6467_27859)">
          <path fillRule="evenodd" clipRule="evenodd" d="M20.9972 9.82988C19.7413 9.25658 18.2587 9.25658 17.0028 9.82988L7.23201 14.2901C6.57993 14.5877 6.19744 15.1925 6.20003 15.8448C6.20261 16.497 6.58963 17.0986 7.24329 17.3918L11 19.0771V23.7999C11 25.3692 12.0973 26.586 13.5253 27.3662C14.9709 28.1561 16.9096 28.5999 19 28.5999C21.0904 28.5999 23.0291 28.1561 24.4748 27.3662C25.9028 26.586 27 25.3692 27 23.7999V19.0771L28.6 18.3593V22.414C28.1218 22.6906 27.8 23.2077 27.8 23.7999C27.8 24.6836 28.5164 25.3999 29.4 25.3999C30.2837 25.3999 31 24.6836 31 23.7999C31 23.2077 30.6783 22.6906 30.2 22.4139V17.6416L30.7567 17.3918C31.4104 17.0986 31.7974 16.497 31.8 15.8448C31.8026 15.1925 31.4201 14.5877 30.768 14.2901L20.9972 9.82988ZM17.6672 11.2854C18.5011 10.9047 19.4989 10.9047 20.3328 11.2854L30.1036 15.7456C30.1621 15.7723 30.1835 15.7996 30.1905 15.8102C30.1984 15.8224 30.2 15.8318 30.2 15.8385C30.2 15.8451 30.1983 15.8547 30.19 15.8672C30.1827 15.8781 30.1608 15.9055 30.1018 15.932L20.3147 20.3226C19.4905 20.6923 18.5095 20.6923 17.6853 20.3226L7.89819 15.932C7.83923 15.9055 7.81727 15.8781 7.80998 15.8672C7.80172 15.8547 7.80004 15.8451 7.80001 15.8385C7.79999 15.8318 7.80158 15.8224 7.80953 15.8102C7.81652 15.7996 7.83795 15.7723 7.89643 15.7456L17.6672 11.2854ZM25.4 19.7949L20.9696 21.7824C19.7288 22.3391 18.2712 22.3391 17.0304 21.7824L12.6 19.7949V23.7999C12.6 24.5354 13.1145 25.3186 14.2924 25.9621C15.4526 26.5961 17.114 26.9999 19 26.9999C20.8861 26.9999 22.5474 26.5961 23.7076 25.9621C24.8855 25.3186 25.4 24.5354 25.4 23.7999V19.7949Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6467_27859">
          <rect width="25.6" height="25.6" fill="white" transform="translate(6.20001 6.2002)" />
        </clipPath>
        <clipPath id="clip1_6467_27859">
          <rect width="25.6" height="25.6" fill="white" transform="translate(6.20001 6.2002)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EduIcon;
