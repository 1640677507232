// Core
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../redux/hooks';

// Slices
import { dropData } from '../../redux/slices/benefitsSlice';
import { setToken } from '../../redux/slices/globalSlice';
import { dropUser, setLoggedOut } from '../../redux/slices/personSlice';
import { deleteAllPersons, dropUserData } from '../../redux/thunks/personThunk';

const DevLogoutPage = () => {
  const isDev = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';

  const [countDown, setCountDown] = useState(4);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (isDev) {
      setTimeout(() => {
        dispatch(deleteAllPersons());
        dispatch(setToken({ token: '' }));
        dispatch(dropUserData());
        dispatch(dropData());
        dispatch(dropUser());
        dispatch(setLoggedOut());
        navigate('/');
      }, 4000);
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);
  return (
    <>
      <p>Страница чистит пользователей и перенаправляет на страницу выбора</p>
      <p>
        До перехода осталось
        {' '}
        {countDown}
      </p>
    </>
  );
};

export default DevLogoutPage;
