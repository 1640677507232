import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import client from '../../functions/api';
import { uniqBy } from '../../functions/uniqBy';
import Benefit from '../../interfaces/benefit.interface';
import OrderDTO from '../../interfaces/orderDTO.interface';
import UserBenefit from '../../interfaces/user-benefit.interface';
import { RootState } from '../store';
import { fetchMe, fetchPerson } from './personThunk';

export const fetchPrograms = createAsyncThunk<Benefit[]>(
  'benefits/fetch',
  async (dispatch, state) => {
    const { data }: { data: Benefit[] } = await client.get('/api/benefits-by-user');
    return data;
  }
)

export const fetchCompensationHistory = createAsyncThunk<Benefit[]>(
  'history/fetch',
  async (dispatch, state) => {
    const { data } = await client.get('/api/compensation-by-user');
    return data;
  }
)

export const fetchPersonBenefits = createAsyncThunk<UserBenefit[]>(
  'benefits/personBenefits',
  async (dispatch, state) => {
    const { data }: { data: UserBenefit[] } = await client.get('/api/person/person-benefits');
    return data;
  }
)

export const addProgram = createAsyncThunk(
  'program/add-program',
  async (orderData: OrderDTO, state) => {
    const { benefits } = (state.getState() as RootState).benefits;

    const programYear = benefits.find((benefit) => benefit._id === orderData.benefit);

    let benefitYear = new Date().getFullYear();
    if (programYear) {
      benefitYear = new Date(programYear.eDate).getFullYear();
    }
    const userData = {
      [benefitYear]: {}
    };

    if (orderData.children?.length) {
      userData[benefitYear]['connectedItProgram'] = true;
    }

    let additionalPrograms = [];
    if (orderData.additionalPrograms?.length) {
      userData[benefitYear]['connectedAdditionalProgramme'] = true;
      additionalPrograms = [...orderData.additionalPrograms].filter((item) => item.isChecked);
    }

    const { data }: { data: any } = await client.post('/api/orders/create-order', {
      ...orderData,
      additionalPrograms: additionalPrograms,
    });

    state.dispatch(fetchPersonBenefits());
    state.dispatch(fetchPrograms());
    state.dispatch(fetchMe());

    return data;
  }
)

export const saveProgram = createAsyncThunk(
  'program/update-one',
  async (orderData: OrderDTO, state) => {
    const { data }: { data: any } = await client.put(`/api/orders/update-one/${orderData._id}`, orderData);

    await state.dispatch(fetchMe());
    await state.dispatch(fetchPersonBenefits());
    await state.dispatch(fetchPrograms());

    return data;
  }
)
export const deleteProgram = createAsyncThunk(
  'program/deleteProgram',
  async (dataToDelete: { id: string, benefitId: string }, state) => {
    const { data }: { data: any } = await client.delete(`/api/orders/cancel-benefit/${dataToDelete.id}/${dataToDelete.benefitId}`);
    await state.dispatch(fetchMe());
    state.dispatch(fetchPersonBenefits());
    state.dispatch(fetchPrograms());
  }
)

// {
//   const dispatch = useDispatch();
//   () => dispatch(fetchPerson);
// }