import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import { mapChildren } from '../../components/HeadingStatus';
import Benefit from '../../interfaces/benefit.interface';
import OrderDTO from '../../interfaces/orderDTO.interface';
import Program from '../../interfaces/program.interface';
import UserBenefit from '../../interfaces/user-benefit.interface';
import { addProgram, deleteProgram, fetchPersonBenefits, fetchPrograms, saveProgram } from '../thunks/fetchPrograms';

type benefitsSlice = {
  benefits: Benefit[];
  userBenefits: UserBenefit[];
  connectForm: boolean;
  editOrder: boolean;
  successOrder: boolean;
  rejectedOrder: boolean;
  currentOrder: OrderDTO;
  currentBenefit: Benefit;
  isReconnecting: boolean;
  redirectUrl: string;
};

const initialState: benefitsSlice = {
  benefits: [] as Benefit[],
  userBenefits: [] as UserBenefit[],
  connectForm: false,
  editOrder: false,
  successOrder: false,
  rejectedOrder: false,
  currentOrder: {} as OrderDTO,
  currentBenefit: {} as Benefit,
  isReconnecting: false,
  redirectUrl: '',
};

const benefitsSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setReconnectionProgram(state, action) {
      state.isReconnecting = action.payload;
    },
    setRedirectUrl(state, action: PayloadAction<{ redirectUrl: string }>) {
      state.redirectUrl = action.payload.redirectUrl;
    },
    dropData(state) {
      state.benefits = [] as Benefit[];
      state.userBenefits = [] as UserBenefit[];
      state.connectForm = false;
      state.editOrder = false;
      state.successOrder = false;
      state.rejectedOrder = false;
      state.currentOrder = {} as OrderDTO;
      state.currentBenefit = {} as Benefit;
    },
    showForm(state) {
      state.connectForm = true;
    },
    hideForm(state) {
      state.connectForm = false;
      state.currentOrder = {} as OrderDTO;
      state.editOrder = false;
      state.successOrder = false;
    },
    successHide(state) {
      state.successOrder = false;
      state.editOrder = false;
    },
    addOrder(state, action: PayloadAction<OrderDTO>) {
      state.connectForm = true;
      state.currentOrder = action.payload;
    },
    setCurrentBenefit(state, action: PayloadAction<{ id: string }>) {
      let result = {} as Benefit;
      state.benefits?.forEach((benefit) => {
        if (benefit.children?.length) {
          benefit.children?.forEach((child) => {
            if (child._id === action.payload.id) {
              result = child;
            }
          })
        }
        if (benefit._id === action.payload.id) {
          result = benefit;
        }
      })
      state.currentBenefit = result;
    },
    addProgramDataToOrder(state, action: PayloadAction<OrderDTO>) {
      const isFree = action.payload.offset === 100 ? true : 0;
      state.currentOrder = {
        ...state.currentOrder,
        program: action.payload.program,
        prefix: action.payload.prefix,
        offset: action.payload.offset,
        creditPlan: isFree ? 0 : action.payload.creditPlan >= 0 ? action.payload.creditPlan : state.currentOrder.creditPlan >= 0 ? state.currentOrder.creditPlan : null,
        signatureUrl: action.payload.signatureUrl !== null ? action.payload.signatureUrl : state.currentOrder.signatureUrl ? state.currentOrder.signatureUrl : null,
      } as OrderDTO
    },
    addAdditionalDataToOrder(state, action: PayloadAction<{ benefitId: string, programmeId: string, prefix: string }>) {
      const { additionalPrograms } = state.currentOrder;
      if (additionalPrograms) {
        const alreadyExistIndex = additionalPrograms.findIndex((addProgramme) => addProgramme.benefit === action.payload.benefitId && addProgramme.isChecked);
        if (alreadyExistIndex >= 0) {
          state.currentOrder = {
            ...state.currentOrder,
            additionalPrograms: additionalPrograms.map((addProgramme) => ({
              ...addProgramme,
              isChecked: false,
              program: null,
              offset: 0,
              creditPlan: 0,
            }))
          }
          return;
        }
        state.currentOrder = {
          ...state.currentOrder,
          additionalPrograms: additionalPrograms.map((addProgramme) => {
            if (addProgramme.benefit === action.payload.benefitId) {
              return {
                ...addProgramme,
                isChecked: true,
                program: action.payload.programmeId,
                prefix: action.payload.prefix,
                offset: 100,
                creditPlan: 0,
              }
            }
            return {
              ...addProgramme,
              isChecked: false,
              program: null,
              offset: 0,
              creditPlan: 0,
            }
          })
        }

      }
    },
    editOrder(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.connectForm = true;
      state.editOrder = true;
      let order = {} as OrderDTO
      const children = [] as OrderDTO[]
      [...current(state.userBenefits)]?.forEach((uBenefit) => {
        if (uBenefit.benefitData.benefit === id) {
          if (uBenefit.benefitData.children?.length) {
            uBenefit.benefitData.children?.forEach((child) => {
              const childOrder = current(state.userBenefits).find((order) => order._id === child);
              if (childOrder) {
                children.push({
                  _id: childOrder._id,
                  benefit: childOrder.benefitData.benefit,
                  program: childOrder.benefitData.program,
                  creditPlan: childOrder.benefitData.creditPlan,
                  offset: childOrder.benefitData.offset,
                  signatureUrl: childOrder.benefitData.signature,
                  prefix: childOrder.benefitData.prefix,
                })
              }
            })
          }
          order = {
            _id: uBenefit._id,
            benefit: uBenefit.benefitData.benefit,
            program: uBenefit.benefitData.program,
            creditPlan: uBenefit.benefitData.creditPlan,
            offset: uBenefit.benefitData.offset,
            signatureUrl: uBenefit.benefitData.signature,
            prefix: uBenefit.benefitData.prefix
          }
        }
      })
      let currentBenefit: Benefit;

      currentBenefit = state.benefits.find((benefit) => benefit._id === id);
      if (children.length) {
        order.children = children;
      }

      state.currentOrder = order;
      if (currentBenefit) {
        state.currentBenefit = currentBenefit;
      }
    },
    addCreditPlanToOrder(state, action: PayloadAction<any>) {
      state.currentOrder = {
        ...state.currentOrder,
        creditPlan: action.payload.creditPlan,
        offset: action.payload.offset,
        program: action.payload.program,
      }
    },

    dropOrder(state, action: PayloadAction<any>) {
      state.currentOrder = {} as OrderDTO;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.fulfilled, (state, { payload }) => {
      if (state.userBenefits.length) {
        state.benefits = payload;
      } else {
        state.benefits = payload;
      }
      if (state.redirectUrl) {
        const redirectId = state.redirectUrl;
        window.location.replace(`program/${redirectId}`);
        state.redirectUrl = '';
      }
    })
    builder.addCase(addProgram.fulfilled, (state, { payload }) => {
      state.userBenefits.push(payload);
      state.successOrder = (!state.isReconnecting);
    })
    builder.addCase(fetchPersonBenefits.fulfilled, (state, { payload }) => {
      state.userBenefits = payload;
    })
    builder.addCase(saveProgram.fulfilled, (state, { payload }) => {
    })
    builder.addCase(deleteProgram.fulfilled, (state, { payload }) => {
    })
  }
});

export const { dropData, showForm, addOrder, hideForm, successHide, addProgramDataToOrder, setCurrentBenefit, addCreditPlanToOrder, addAdditionalDataToOrder, editOrder, setReconnectionProgram, setRedirectUrl } = benefitsSlice.actions;
export default benefitsSlice.reducer;
