import React, { FC, useState, useEffect, useRef } from 'react';
import { Button, Input, Loader, MenuItem, MenuSeparator } from '@skbkontur/react-ui';
import styled from 'styled-components';
import { SearchLoupeIcon24Regular } from '@skbkontur/icons';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router';
import AdminPageWrapper from '../../layouts/admin/AdminPageWrapper/AdminPageWrapper';
import useDebounce from '../../functions/useDebounce';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAllPersons } from '../../redux/thunks/personThunk';
import {
  getAllPersons,
  getIsPersonsLoading,
  getPersonsCount,
} from '../../redux/selectors/person.selector';
import Person from '../../interfaces/person.interface';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import DropdownMenuItem, {
  ItemBody,
  ItemFooter,
} from '../../components/DropdownMenu/DropdownMenuItem';
import ProgramStub from '../../components/ProgramStub';
import { clearPersons } from '../../redux/slices/personSlice';
import useLocalStorage from '../../hooks/useLocalStorage';
import UserSearchHistory from './UserSearchHistory/UserSearchHistory';

const InputWrapper = styled.div`
  width: 100%;

  svg {
    width: 24px;
    height: 24px;
  }

  input {
    font-size: 18px;
    padding-left: 6px;
  }

  label {
    width: 100%;
    font-weight: 400;
    padding: 22px 16px;
  }
`;

const SearchUserPage: FC = () => {
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const users = useAppSelector(getAllPersons);
  const isPersonsLoading = useAppSelector(getIsPersonsLoading);
  const debouncedValue = useDebounce<string>(search, 500);
  const searchRef = useRef(null);
  const [open, setOpen] = useState(false);
  const usersCount = useAppSelector(getPersonsCount);
  const navigate = useNavigate();
  const [historyUsers, setHistoryUsers] = useLocalStorage('historyUsers', []);
  const MAX_HISTORY_COUNT = 30;

  useEffect(() => {
    if (debouncedValue !== '') {
      dispatch(fetchAllPersons(debouncedValue));
    } else {
      setOpen(false);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!isPersonsLoading && debouncedValue !== '') {
      setOpen(true);
    }
  }, [isPersonsLoading]);

  const addUserToHistory = (addUser:Person) => {
    const alreadyExisted = (historyUsers as Person[]).some((user) => user._id === addUser._id);
    if (alreadyExisted) {
      const newUsers = (historyUsers as Person[]).filter((user) => user._id !== addUser._id);
      newUsers.unshift(addUser);
      setHistoryUsers(newUsers);
    } else {
      historyUsers.unshift(addUser);
      if (historyUsers.length > MAX_HISTORY_COUNT) {
        historyUsers.length = MAX_HISTORY_COUNT;
      }
      setHistoryUsers(historyUsers);
    }
  };

  return (
    <AdminPageWrapper>
      <div onFocus={() => setOpen(true)}>
        <InputWrapper>
          <Input
            onBlur={() => {
              setSearch('');
              setOpen(false);
              dispatch(clearPersons());
            }}
            placeholder="ФИО или email сотрудника"
            ref={searchRef}
            leftIcon={<SearchLoupeIcon24Regular />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputWrapper>
        <DropdownMenu open={open && debouncedValue !== ''}>
          <Loader active={isPersonsLoading} type="normal" delayBeforeSpinnerShow={0} minimalDelayBeforeSpinnerHide={0}>
            {users?.length > 0 ? (
              <>
                {users.map((user: Person) => (
                  <DropdownMenuItem
                    key={user._id}
                    onClick={() => {
                      addUserToHistory(user);
                      navigate(`/admin/employee/${user._id}`);
                    }}
                  >
                    <ItemBody>
                      <Highlighter
                        highlightClassName="text-highlight"
                        autoEscape
                        searchWords={debouncedValue.split(' ')}
                        textToHighlight={`${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`}
                        sanitize={(text:string) => text.toLocaleLowerCase().replace('ё', 'е')}
                      />
                    </ItemBody>
                    <ItemFooter>
                      <Highlighter
                        highlightClassName="text-highlight"
                        autoEscape
                        searchWords={debouncedValue.split(' ')}
                        textToHighlight={user?.users[0]?.primaryEmail}
                      />
                    </ItemFooter>
                  </DropdownMenuItem>
                ))}
                {usersCount > users?.length && (
                <>
                  <MenuSeparator />
                  <DropdownMenuItem clickable={false}>
                    <ItemFooter>
                      Показано
                      {' '}
                      {users?.length}
                      {' '}
                      из
                      {' '}
                      {usersCount}
                      {' '}
                      сотрудников.
                      Уточните запрос, чтобы увидеть остальных
                    </ItemFooter>
                  </DropdownMenuItem>
                </>
                )}
              </>
            ) : (
              <DropdownMenuItem clickable={false}>
                <ItemFooter>Ничего не найдено</ItemFooter>
              </DropdownMenuItem>
            )}
          </Loader>
        </DropdownMenu>
      </div>
      {historyUsers.length > 0 ? <UserSearchHistory users={historyUsers} /> : <ProgramStub title="Выберите сотрудника" />}

    </AdminPageWrapper>
  );
};

export default SearchUserPage;
