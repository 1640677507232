import { Button, SidePage } from '@skbkontur/react-ui';
import styled from 'styled-components';
import { TagWrapper } from '../InfoTag/InfoTag.styled';

export const SidePageWrapper = styled(SidePage)`
  & > div > div > div {
    height: 100%;
  }
`;

export const SidePageOverHeader = styled(SidePage.Header)`
  & > div {
    padding: 25px 0 0;
  }
`;

export const SidePageMainHeading = styled.div`
  display: inline-block;
  h4 {
    display: inline-block;
  }
  ${TagWrapper} {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const AddsTitle = styled.h4`
  margin: 0;
  font: 500 18px/24px LabGrotesqueK, sans-serif;
`;

export const SidePageChildren = styled.div`
  ${AddsTitle} {
    margin: 40px 30px 16px;
  }
`;

export const SidePageOverFooter = styled(SidePage.Footer) <{ isShowShadow?: boolean }>`
  & > div {
    z-index: 1;
    background-color: #fff;
    position: fixed;
    box-shadow: ${(props) => (props.isShowShadow ? '0px -1px 2px rgba(0, 0, 0, 0.14), 0px -1px 8px rgba(0, 0, 0, 0.1)' : 'none')};
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  gap: 18px;
`;

export const StyledButton = styled(Button)`
  margin: 8px 0 0;
  button {
    border: 1px solid #D9472B !important;
  }
`;

export const TooltipMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  font: var(--small-text);
`;

export const DisabledSpan = styled.span`
display: block;
  font: var(--default-text);
  margin: auto 0 0;
  color: var(--secondary-text);
  font-size: 14px;
  padding: 0 0 14px 0;
`