import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowChevronLeft } from '@skbkontur/react-icons';
import { Hint } from '@skbkontur/react-ui';
import { DefaultLink, Profession, TitleWrapper, UserPageWrapper } from './styled/components';
import Title from '../../../components/Title';
import EduIcon from '../../../icons/EduIcon';
import ProgramStub from '../../../components/ProgramStub';

const UserPage = () => {
  return (
    <UserPageWrapper>
      <div>
        <TitleWrapper>
          <Title type="h2">
            <DefaultLink to="/">
              <ArrowChevronLeft />
            </DefaultLink>
            <DefaultLink to="#">Олегов Олег Олегович</DefaultLink>
          </Title>
        </TitleWrapper>
        <Profession>
          <p>Копирайтер</p>
          <Hint pos="right" text="Испытательный срок">
            <EduIcon />
          </Hint>
        </Profession>
      </div>
      <ProgramStub title="Пока нет программ" />
      <Title type="h2">Можно подключить</Title>
    </UserPageWrapper>
  );
};

export default UserPage;
