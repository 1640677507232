/* eslint-disable react-hooks/rules-of-hooks */
import { Radio, RadioGroup } from '@skbkontur/react-ui';
import { add, eachMonthOfInterval } from 'date-fns';
import NumberFormat from 'react-number-format';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentBenefitSelector, currentOrderSelector, editFormSelector, userBenefitSelector } from '../../../redux/selectors/benefits.selector';
import { RadioGroupWrapper, RadioLabel } from '../../AvailablePrograms/AvailablePrograms.styled';
import { CreditPlan, Divider, RadioLabelSub, SignInput } from '../SidePageAccordion.styled';
import OrderDTO from '../../../interfaces/orderDTO.interface';
import { addCreditPlanToOrder, addProgramDataToOrder } from '../../../redux/slices/benefitsSlice';
import Program from '../../../interfaces/program.interface';
import declOfNum from '../../../functions/declOfNum';
import { OffsetProgramsContainer, OffsetProgramsWrapper } from './ProgramsWIthOffset.styled';

type DatePrice = {
  date?: Date,
  price: number,
  text: string,
  number: number,
};

const ProgramsWithOffset: FC = () => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(currentOrderSelector);
  const editMode = useSelector(editFormSelector);
  const currentBenefit = useSelector(currentBenefitSelector);
  const userBenefits = useSelector(userBenefitSelector);

  const [withPrice, setWithPrice] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<Program>({} as Program);
  const [dates, setDates] = useState<DatePrice[]>([] as DatePrice[]);
  const [creditPlan, setCreditPlan] = useState<DatePrice>({} as DatePrice);

  const freeProgram = currentBenefit.programs
    .find((item) => item.offsetCondition
      .find((offset) => offset.offset === 100));

  if (!freeProgram) {
    return (
      <>
      </>
    );
  }

  const handleValueChange = (e) => {
    const { value } = e.target;
    const programme = currentBenefit.programs.find((benefitProgramme) => benefitProgramme._id === value) as Program;
    if (programme) {
      setCurrentProgram(programme);

      const orderData: OrderDTO = {
        ...currentOrder,
        program: value,
        offset: programme._id === freeProgram._id ? 100 : 0,
        creditPlan: null,
        prefix: programme.prefix,
      };
      dispatch(addProgramDataToOrder(orderData));
    }
    if (!programme) {
      const notFreeProgramme = currentBenefit.programs.length === 1 ? currentBenefit.programs[0] : currentBenefit.programs.find((benefitProgramme) => benefitProgramme._id !== freeProgram._id) as Program;
      setCurrentProgram(notFreeProgramme);

      const orderData: OrderDTO = {
        ...currentOrder,
        program: notFreeProgramme?._id,
        offset: 0,
        prefix: notFreeProgramme?.prefix,
        creditPlan: null,
      };
      dispatch(addProgramDataToOrder(orderData));
    }
    if (value === 'withPrice' || (currentBenefit.programs.length > 1 && value !== freeProgram._id)) {
      setWithPrice(true);
      return;
    }

    setWithPrice(false);
  };

  const mapDates = () => {
    const eDate = add(new Date(), { months: 3 });
    const months = eachMonthOfInterval({ start: add(new Date(), { months: 0 }), end: eDate });
    const mappedDate = months.map((month, idx) => {
      if (idx === 0) {
        return {
          text: 'Без рассрочки, вычесть сразу',
          price: currentProgram?.price?.price,
          number: 0,
        };
      }
      if (idx === 1) {
        return;
      }
      return {
        date: month,
        price: currentProgram?.price?.price / idx,
        text: `${idx} ${declOfNum(idx, ['месяц', 'месяца', 'месяцев'])}`,
        number: idx,
      };
    }).filter((item) => item);
    setDates(mappedDate);
    // setCreditPlan(mappedDate[0]);
    if (editMode) {
      const notPackageCreditPlan = userBenefits.find((userBenefit) => userBenefit.benefitId === currentBenefit._id).creditPlan;
      // setCreditPlan(mappedDate.find((date) => date.number === notPackageCreditPlan) || mappedDate[0]);
    }
  };

  const handleCreditPlanChange = (idx) => {
    setCreditPlan(dates[idx]);

    dispatch(addCreditPlanToOrder({
      ...currentOrder,
      children: [],
      offset: 0,
      creditPlan: dates[idx].number,
    }));
  };

  const handleInputValueChange = (e: string) => {
    const orderData: OrderDTO = {
      ...currentOrder,
      signatureUrl: e,
    };
    dispatch(addProgramDataToOrder(orderData));
  };

  useEffect(() => {
    if (editMode && (currentOrder.program !== freeProgram._id || currentOrder.offset !== 100)) {
      const notFreeProgramme = currentBenefit.programs.find((benefitProgramme) => {
        if (currentOrder.program === benefitProgramme._id && currentOrder.offset !== 100) {
          return true;
        }
        return benefitProgramme._id !== freeProgram._id;
      }) as Program;
      setCurrentProgram(notFreeProgramme);
      setWithPrice(true);
    }
  }, []);

  useEffect(() => {
    if ((currentOrder.program !== freeProgram._id) || currentBenefit.programs.length === 1 || (currentOrder.program === freeProgram._id && currentOrder.offset !== 100)) {
      mapDates();
    }
  }, [currentProgram]);

  return (
    <>
      <OffsetProgramsWrapper>
        <RadioGroup>
          <RadioGroupWrapper>
            <Radio
              checked={!withPrice && (freeProgram._id === currentOrder.program && currentOrder.offset === 100)}
              onClick={(e) => handleValueChange(e)}
              value={freeProgram._id}
              key={freeProgram._id}
              name={freeProgram._id}
            >
              <RadioLabel>
                Бесплатно (
                {freeProgram.title}
                )
                <RadioLabelSub>
                  При массовой регистрации вы можете бесплатно подключить одну дополнительную программу на будущий год
                </RadioLabelSub>
              </RadioLabel>
            </Radio>

            <Radio
              checked={withPrice}
              onClick={(e) => handleValueChange(e)}
              value="withPrice"
              key="withPrice"
              name="withPrice"
            >
              <RadioLabel>
                Платно
              </RadioLabel>

            </Radio>
            {withPrice && (
              <>
                <OffsetProgramsContainer>
                  <RadioGroupWrapper>
                    {currentBenefit.programs
                      .filter((program) => currentBenefit.programs.length === 1 || program._id !== freeProgram._id || (freeProgram._id === currentOrder.program && currentOrder.offset !== 100))
                      .map((program) => {
                        return (
                          <>
                            <Radio
                              checked={currentOrder.program === program._id}
                              onClick={(e) => handleValueChange(e)}
                              value={program._id}
                              key={program._id}
                              name={program._id}
                            >
                              <RadioLabel>
                                <span>{program.title}</span>
                                <RadioLabelSub>
                                  <NumberFormat value={Math.round(program.price.price)} displayType="text" thousandSeparator={' '} />
                                  {' '}
                                  {program.sign ? program.sign : '₽'}
                                </RadioLabelSub>
                              </RadioLabel>
                            </Radio>
                          </>
                        );
                      })}
                  </RadioGroupWrapper>
                  <>
                    <CreditPlan onClick={(e) => e.stopPropagation()}>
                      <RadioGroup
                        value={dates}
                      >
                        <RadioGroupWrapper>
                          <span>Рассрочка</span>
                          {dates.map((datePrice, idx) => (
                            <>
                              <Radio
                                checked={currentOrder.creditPlan === datePrice.number}
                                onClick={(e) => handleCreditPlanChange(idx)}
                                value={datePrice.price}
                                key={datePrice.price}
                                name={datePrice.text}
                              >
                                <RadioLabel>
                                  <span>{datePrice.text}</span>

                                  <RadioLabelSub>
                                    по
                                    {' '}
                                    <NumberFormat value={Math.round(datePrice.price)} displayType="text" thousandSeparator={' '} />
                                    {' '}
                                    {currentProgram?.sign ? currentProgram.sign : '₽'}

                                  </RadioLabelSub>
                                </RadioLabel>
                              </Radio>
                            </>
                          ))}
                        </RadioGroupWrapper>
                      </RadioGroup>
                    </CreditPlan>
                  </>
                </OffsetProgramsContainer>

              </>
            )}
          </RadioGroupWrapper>
        </RadioGroup>
      </OffsetProgramsWrapper>
      {withPrice && (
        <>
          <Divider />
          <div>
            <span>
              Подпишите заявление об удержании стоимости
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wiki.skbkontur.ru/pages/viewpage.action?pageId=730182151"
              >
                в&nbsp;Контур.Документах
              </a>
              {' '}
              и вставьте ссылку на него
            </span>
            <SignInput placeholder="Ссылка на заявление" onValueChange={handleInputValueChange} value={currentOrder.signatureUrl} />
          </div>
        </>
      )}
    </>
  );
};

export default ProgramsWithOffset;
