import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const UserPageWrapper = styled.div`
    padding-top:20px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    h1, h2, p {
        margin: 0;
    }
`;

export const DefaultLink = styled(Link)`
    color: #222222;
    text-decoration: none;
`;

export const TitleWrapper = styled.div`
    position: relative;
    margin-bottom: 10px;

    svg {
        position: absolute;
        top: 50%;
        left:0;
        transform: translate(-110%, -50%)
    }
`;

export const Profession = styled.div`
    color:#222222;
    display:flex;
    align-items: center;
    gap:10px;

    svg {
        cursor: pointer;
    }
`;

export default UserPageWrapper;
